<template>
  <div class="container">
    <div
      class="item"
    >
      <Cloud></Cloud>
    </div>

    <div
      class="item"
    >
      <Ihs></Ihs>
    </div>

    <div
      class="item"
    >
      <Huggy></Huggy>
    </div>

    <div
      class="item"
    >
      <Tsuru></Tsuru>
    </div>

    <div
      class="item"
    >
      <Solids></Solids>
    </div>

    <div
      class="item"
    >
      <Secullum></Secullum>
    </div>
  </div>
</template>

<script>
import Cloud from './GridComponents/CloudSystems.vue'
import Huggy from './GridComponents/HuggySystems.vue'
import Ihs from './GridComponents/IhsSystems.vue'
import Secullum from './GridComponents/SecullumSystems.vue'
import Solids from './GridComponents/SolidsSystems.vue'
import Tsuru from './GridComponents/TsuruSystems.vue'

export default {
  components: {
    Cloud,
    Ihs,
    Huggy,
    Tsuru,
    Solids,
    Secullum,
  },

  props: {
    accessSystems: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.container {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 25px;

  overflow-x: hidden;
}

@media (max-width: 760px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 540px) {
  .container {
    grid-template-columns: 1fr;
    height: 100%;
  }
}

.item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
